<template>
  <div id="debt">
    <b-modal
      id="md-debt"
      hide-footer
      title="Cập nhật phương thức thanh toán:"
      no-close-on-backdrop
      size="md"
    >
      <b-overlay
        :show="isBusy"
        variant="white"
        :opacity="0.3"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-row>
          <b-col cols="6" aria-controls="overlay-background">
            <b-form-group>
              <label>Dịch vụ trả góp:</label>
              <vue-autosuggest
                :suggestions="optionInstallment"
                v-model="debt.installMoneyAccountName"
                :limit="100"
                :input-props="{
                  class: 'autosuggest__input',
                  placeholder: 'Dịch vụ trả góp',
                }"
                @selected="
                  (option) => {
                    debt.installMoneyAccountId = option.item.id;
                    debt.installMoneyAccountName = option.item.name;
                  }
                "
              >
                <div slot-scope="{ suggestion }">
                  {{ suggestion.item.name }}
                </div>
              </vue-autosuggest>
            </b-form-group>
            <b-form-group>
              <label>Mã hợp đồng:</label>
              <b-input
                placeholder="Mã hợp đồng"
                v-model="debt.installMoneyCode"
                size="sm"
              />
            </b-form-group>
            <b-form-group>
              <label>Số CMND:</label>
              <b-input
                placeholder="CMND hoặc CCCD"
                v-model="debt.customerIndentifyNo"
                size="sm"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>
      <b-row class="mt-5">
        <b-col class="d-flex">
          <b-overlay
            opacity="0.6"
            spinner-variant="white"
            spinner-type="grow"
            spinner-small
            :show="isBusy"
          >
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="updateItem"
              >Cập nhật</b-button
            >
          </b-overlay>

          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            @click="$bvModal.hide('md-debt')"
            >Hủy</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<style></style>

<script>
// import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
// import { minLength } from 'vuelidate/lib/validators';
import ApiService from '@/core/services/api.service';
// import { currencyMask, unMaskPrice } from './../../utils/common';
// import { checkPermissions } from '../../../utils/saveDataToLocal';
import { makeToastFaile, makeToastSuccess } from '../../../utils/common';
// import { ORDER_STATUS, ORDER_CANCEL_STATUS } from '../../../utils/constants';
import { cloneDeep } from 'lodash';
export default {
  props: ['item', 'mode'],
  mixins: [validationMixin],
  data() {
    return {
      debt: {},
      isBusy: false,
      filteredInstallments: [],
    };
  },
  computed: {
    optionInstallment() {
      return [{ data: cloneDeep(this.filteredInstallments) }];
    },
  },
  created() {
    this.fetchInstallments();
  },
  watch: {
    $props: {
      handler() {
        this.debt = {};
        switch (this.mode) {
          case 'items': {
            this.isBusy = true;
            if (this.item.billNumber) {
              this.fetchBillById(this.item.billNumber);
            }
            break;
          }
          case 'installment-orders': {
            this.isBusy = true;
            if (this.item.orderId) {
              this.fetchOrderById(this.item.orderId);
            }
            break;
          }
          default:
            break;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {},
  methods: {
    getDataUpdatePayment() {
      return {
        id: this.debt.id,
        installMoneyAccountId: this.debt.installMoneyAccountId,
        installMoneyCode: this.debt.installMoneyCode,
        customerIndentifyNo: this.debt.customerIndentifyNo,
      };
    },
    updateItem: function () {
      this.isBusy = true;

      const item = this.getDataUpdatePayment();
      let url = null;

      if (this.mode === 'items') {
        url = '/bills/payment';
      } else {
        url = '/orders/common-payment';
      }

      ApiService.put(url, item)
        .then((response) => {
          this.isBusy = false;
          this.$bvModal.hide('md-debt');
          if (response.data.status) {
            makeToastSuccess('Cập nhật thành công');
            this.$emit('refresh', null);
          }
        })
        .catch(() => {
          this.isBusy = false;
          this.$bvModal.hide('md-debt');
        });
    },
    fetchOrderById: function (orderId) {
      ApiService.query('/orders/' + orderId)
        .then((response) => {
          if (response.data.status) {
            this.isBusy = false;
            const order = response.data.data;
            this.debt = {
              id: order.id,
              installMoneyAccountName: order.installmentAccName,
              installMoneyAccountId: order.installMoneyAccountId,
              installMoneyCode: order.installMoneyCode,
              customerIndentifyNo: order.customerIndentifyNo,
            };
          }
        })
        .catch(() => {
          makeToastFaile('Lấy dữ liệu không thành công');
        });
    },
    fetchBillById: function (billId) {
      ApiService.query('/bills/' + billId)
        .then((response) => {
          if (response.data.status) {
            this.isBusy = false;
            const bill = response.data.data;
            this.debt = {
              id: bill.id,
              installMoneyAccountName: bill.installmentAccName,
              installMoneyAccountId: bill.installMoneyAccountId,
              installMoneyCode: bill.installMoneyCode,
              customerIndentifyNo: bill.customerIndentifyNo,
            };
          }
        })
        .catch(() => {
          makeToastFaile('Lấy dữ liệu không thành công');
        });
    },
    fetchInstallments: function () {
      ApiService.query('/installments', {
        params: {
          page: 1,
          pageSize: 1000,
        },
      }).then((response) => {
        const installments = response.data.data.result;
        this.filteredInstallments = installments;
      });
    },
  },
};
</script>

<style>
.autosuggest__results {
  position: absolute;
  width: 100%;
  z-index: 1;
  max-height: 200px;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.35em + 1.1rem + 2px);
  line-height: 1.35;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  font-size: 0.925rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.28rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>